import React from "react"
import SEO from '../components/seo'
import styled from 'styled-components'
import Background from '../components/Background'
import Wrapper from '../components/Wrapper'
import Heading from '../components/Heading'
import Button from '../components/Button'
import Paragraph from '../components/Paragraph'
import Menu from '../components/Menu'
import ContactRow from "../components/ContactRow"

const PaddingProvider = styled.div`
  padding-bottom: 60px;
  max-width: 600px;
  margin: auto;

  & p:nth-child(2) {
    margin-top: 10px;
  }
`

const ButtonWrapper = styled.div`
  z-index: 2; 
  margin-bottom: 60px;
  display: flex; 
  justify-content: center; 
  align-self: center;
`

const ContactLink = styled.a`
  cursor: pointer; 
  text-decoration: none;
`

export default () => {
  return (
    <>
      <SEO
        title={ 'Brester - Contact me'}
        description={ 'Contact me for freelance work' }
        lang={ 'en' }
      />
      <Background>
        <Wrapper>
          <Heading>Contact</Heading>
          <ButtonWrapper>
            <ContactLink href="mailto:boris.brestnichki@gmail.com">
              <Button>Email me</Button>
            </ContactLink> 
          </ButtonWrapper>
          <PaddingProvider>
            <Paragraph>
              I'm currently looking for freelance work. 
              If you have a project you want to discuss, 
              please feel free to contact me. 
              
            </Paragraph>
            <Paragraph>
              I offer high-quality delivery and 
              competitive prices.
            </Paragraph>
          </PaddingProvider>
          <ContactRow />
          <Menu />
        </Wrapper>
      </Background>
    </>
  )
}