import React from 'react'
import styled from 'styled-components'
import Paragraph from './Paragraph'

const Button = styled.button`
  padding: 5px 20px; 
  background-color: transparent;
  border: 1px solid #fff; 
  border-radius: 5px;
  outline: none; 

  &:hover{
    background: #fff;
  }

  &:hover p {
    color: rgba(13,107,115);
  }
`

export default ({ children }) => <Button><Paragraph>{ children }</Paragraph></Button>