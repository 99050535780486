import React from 'react'
import styled from 'styled-components'
import Paragraph from './Paragraph'
import FacebookUrl from '../../static/images/facebook.svg'
import EmailUrl from '../../static/images/email.svg'
import TwitterUrl from '../../static/images/twitter.svg'

const Flex = styled.div`
  margin-top: 20px;
  display: flex; 
  justify-content: center;
  align-items: center; 
  margin-bottom: 40px;
`

const Item = styled.a`
  font-decoration: none;
  z-index: 2;

  & img{ 
    height: 24px;
    margin: 0 20px;
  }
`

const items = [
  {
    url: FacebookUrl,
    href: 'https://www.facebook.com/boris.brestnicki',
    alt: 'Facebook Icon'
  },
  {
    url: EmailUrl,
    href: 'mailto:boris.brestnichki@gmail.com',
    alt: 'Email Icon'
  },
  {
    url: TwitterUrl,
    href: 'https://twitter.com/BBrestnichki',
    alt: 'Twitter Icon'
  }
]

export default () => {
  return (
    <>
      <Paragraph>Find me</Paragraph>
      <Flex>
        { items.map((item, index) => {
          return (
            <Item
              key={ index }
              href={ item.href }
            >
              <img 
                src={ item.url } 
                alt={ item.alt }
              />
            </Item>
          )
        })}
      </Flex>
    </>
  )
}